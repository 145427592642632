export const STATUS_LABEL_TYPE = {
  ok: 'ok',
  warning: 'warning',
  critical: 'critical',
  disabled: 'disabled'
} as const;

export interface IStatusLabelProps {
  status: (typeof STATUS_LABEL_TYPE)[keyof typeof STATUS_LABEL_TYPE];
  customText?: string | null;
  customIcon?: React.ReactNode | null;
}
