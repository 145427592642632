export enum DONUT_CHART_TYPE {
  big = 'big',
  medium = 'medium'
}

export interface IDonutChartProps {
  type: DONUT_CHART_TYPE;
  label?: string;
  data: { value: number; color: string }[];
}
