import byteSize from 'byte-size';

const ISUCheck = (): boolean => {
  const useISU = localStorage.getItem('useISU');

  return useISU === 'true';
};

export const MB = (): number => {
  return ISUCheck() ? 1000000 : 1048576;
};

export const GB = (): number => {
  return ISUCheck() ? 1000000000 : 1073741824;
};

export const TB = (): number => {
  return ISUCheck() ? 1000000000000 : 1099511627776;
};

export const getParsedValueObj = (value: number) => {
  let parsedValue = null;

  if (ISUCheck()) {
    parsedValue = byteSize(value, { precision: value >= TB() ? 2 : 1 });
  } else {
    parsedValue = byteSize(value, { units: 'iec', precision: value >= TB() ? 2 : 1 });
  }

  return {
    ...parsedValue,
    value: parsedValue?.value ? +parseFloat(parsedValue.value.replace(/,/g, '.')).toFixed(2) : ''
  };
};

const getParsedNumber = (num: number) => {
  return typeof num === 'number' ? num : null;
};

const getParsedMetric = (str: string) => {
  return typeof str === 'string' ? str : '';
};

export const convertBytes = (value: number): string => {
  const parsedValue = typeof value === 'number' ? getParsedValueObj(value) : null;

  return parsedValue
    ? `${getParsedNumber(parsedValue.value as number)} ${getParsedMetric(parsedValue.unit)}`
    : '';
};
