import { createSelector } from '@reduxjs/toolkit';
import { getAlertsCountArr } from 'commonExtensions/common';
import {
  networkAssignmentLocale,
  NETWORKS_DEFAULT_SORT,
  networkStatusLocale
} from 'constants/networks';
import { selectAccountSettings } from 'store/selectors/account';
import { TABLE_TYPE } from 'constants/table';
import {
  RootState,
  IAlertsCount,
  INetworksResponseDataRows,
  IAdapterId,
  IAdapterName,
  ITableBody,
  INetworksResponseDataAdapters,
  IAdapterIpAddress,
  IAdapterBandwidth,
  IAdapterSpeed,
  IAdapterMtu,
  ADAPTER_ASSIGNMENT,
  ADAPTER_STATUS,
  INodeId
} from 'types';
import { convertBytes } from 'commonExtensions/bytesParser';

class NodeNetworksTable {
  id!: IAdapterId;
  nodeId: INodeId;
  name!: IAdapterName;
  status: ADAPTER_STATUS;
  ipAddress: IAdapterIpAddress;
  bandwidth: IAdapterBandwidth;
  speed: IAdapterSpeed;
  mtu: IAdapterMtu;
  assignment: ADAPTER_ASSIGNMENT;
  alertsCount!: IAlertsCount;

  constructor(data: INetworksResponseDataAdapters & { nodeId: INodeId }) {
    this.id = data.id;
    this.nodeId = data.nodeId;
    this.name = data.name;
    this.status = data.status;
    this.ipAddress = data.ipAddress;
    this.bandwidth = data.bandwidth;
    this.speed = data.speed;
    this.mtu = data.mtu;
    this.assignment = data.assignment;
    this.alertsCount = data.alertsCount;
  }

  get getBandwidth() {
    return `${convertBytes(this.bandwidth)}/s`;
  }

  get getSpeed() {
    return `${convertBytes(this.speed)}/s`;
  }

  get getMtu() {
    return convertBytes(this.mtu);
  }

  get getAssignment() {
    return networkAssignmentLocale[this.assignment] || '';
  }

  get getStatus() {
    return networkStatusLocale[this.status] || { text: '', statusLabel: '' };
  }

  get getAlerts() {
    return getAlertsCountArr(this.alertsCount);
  }

  get getCriticalAlerts() {
    return this.alertsCount?.critical || 0;
  }

  get getWarningAlerts() {
    return this.alertsCount?.warning || 0;
  }

  get getAlertsSortColumns() {
    return ['getCriticalAlerts', 'getWarningAlerts'];
  }
}

export const selectNetworks = (state: RootState) => state.networks.networks?.rows;

export const selectAllNetworks = createSelector([selectNetworks], (networksItems) => {
  return (networksItems || []).reduce(
    (result: INetworksResponseDataAdapters[], item: INetworksResponseDataRows) => {
      const adapters = item.adapters.map((adapter) => ({ ...adapter, nodeId: item.id }));
      return [...result, ...adapters] as (INetworksResponseDataAdapters & { nodeId: INodeId })[];
    },
    []
  );
});

export const selectNetworksTableData = createSelector(
  [selectAllNetworks, selectAccountSettings],
  (networks, accountSettings) => {
    return {
      body: networks?.map((item) => new NodeNetworksTable(item) as ITableBody) || [],
      config: {
        defaultSort: NETWORKS_DEFAULT_SORT
      },
      savedSettings:
        accountSettings?.personalization?.table?.find((item) => item?.id === TABLE_TYPE.NETWORK)
          ?.columns || [],
      type: TABLE_TYPE.NETWORK
    };
  }
);
