import * as api from 'api/api';
import { axiosPrivate } from 'api/axiosPrivate';
import { setAlerts } from 'store/features/alerts/alertsSlice';
import { AppDispatch } from 'types';

export const getAlerts = () => async (dispatch: AppDispatch) => {
  await axiosPrivate
    .get(`${api.alerts}`)
    .then((response) => {
      dispatch(setAlerts(response?.data || null));
    })
    .catch((error) => {
      console.error('error', error);
    });
};
