import * as api from 'api/api';
import { axiosPrivate } from 'api/axiosPrivate';
import { setDisks } from 'store/features/disks/disksSlice';
import { AppDispatch, IDisksGetRequestProps } from 'types';

export const getDisks =
  ({ clusterId }: IDisksGetRequestProps) =>
  (dispatch: AppDispatch) => {
    axiosPrivate
      .get(`${api.clusters}/${clusterId}/disks`)
      .then((response) => {
        dispatch(setDisks(response?.data || null));
      })
      .catch((error) => {
        console.error('error', error);
      });
  };
