import { FC, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Loader from 'components/Loader';
import { url, authorization as authUrl } from '../../api/api';
import { BUTTON_BIG_TYPE } from 'commonExtensions/constants';
import { setAuthorizationError } from 'store/features/app/appSlice';
import { authorization } from 'store/actions';
import { selectAuthorizationFetching, selectAuthorizationError } from 'store/selectors';
import Input from 'components/Input';
import ButtonBig from 'components/ButtonBig';

import { KEYBOARD_KEYCODE, LOGIN_FORM_DATA, LOADER_TYPE } from 'commonExtensions/constants';
// import { encode } from 'commonExtensions/encodeDecode';
import {
  IInputChangeEvent,
  ILoginFormData,
  KeyboardEvent,
  MouseClickEvent,
  Pending,
  AppDispatch,
  IAuthorizationError
} from 'types';

import locale from 'locale';

import './styles.scss';

const Login: FC = () => {
  const dispatch: AppDispatch = useDispatch();
  const authorizationFetching: Pending = useSelector(selectAuthorizationFetching);
  const authorizationError: IAuthorizationError = useSelector(selectAuthorizationError);
  const [formData, setFormData] = useState<ILoginFormData | null>(null);

  useEffect(() => {
    if (authorizationError) {
      dispatch(setAuthorizationError(''));
    }
  }, [dispatch, authorizationError]);

  const onLoginInputChange = (e: IInputChangeEvent) => {
    setFormData({
      ...formData,
      [LOGIN_FORM_DATA.username]: e.target.value || ''
    });
  };

  const onPassInputChange = (e: IInputChangeEvent) => {
    setFormData({
      ...formData,
      [LOGIN_FORM_DATA.password]: e.target.value || ''
    });
  };

  const formIsValid = () => {
    return formData?.[LOGIN_FORM_DATA.username] && formData?.[LOGIN_FORM_DATA.password];
  };

  const onConfirmClick = async (e: KeyboardEvent | MouseClickEvent) => {
    if (formIsValid()) {
      const dataToSend = {
        [LOGIN_FORM_DATA.username]: formData?.[LOGIN_FORM_DATA.username] || '',
        // [LOGIN_FORM_DATA.password]: encode(formData?.[LOGIN_FORM_DATA.password] || '')
        [LOGIN_FORM_DATA.password]: formData?.[LOGIN_FORM_DATA.password] || ''
      };
      dispatch(authorization(dataToSend));
    }

    e.preventDefault();
  };

  const onKeyDown = (e: KeyboardEvent) => {
    if (e.key === KEYBOARD_KEYCODE.enter && formIsValid()) {
      onConfirmClick(e);
    }
  };

  return (
    <>
      {authorizationFetching ? (
        <Loader type={LOADER_TYPE.authorization} />
      ) : (
        <div className="login__page_wrapper">
          <div className="login__form_wrapper" onKeyDown={onKeyDown}>
            <div className="login__form_logo"></div>
            <div className="login__form_welcome_title">{locale('IDS_LOGIN_WELCOME_CAPTION')}</div>
            <div className="login__form_input">
              <Input
                label={'Username'}
                value={formData?.[LOGIN_FORM_DATA.username] || ''}
                onChange={onLoginInputChange}
              />
            </div>
            <div className="login__form_input">
              <Input
                label={'Password'}
                value={formData?.[LOGIN_FORM_DATA.password] || ''}
                onChange={onPassInputChange}
              />
            </div>
            {authorizationError && <p className="login__form_error">{authorizationError || ''}</p>}
            <div className="login__form_button_login_wrapper">
              <ButtonBig
                disabled={!formIsValid()}
                caption={locale('IDS_LOGIN_BTN_CAPTION')}
                type={BUTTON_BIG_TYPE.primary}
                onClick={() => onConfirmClick}
              />
            </div>
            <div className="login__form_sep_line"></div>
            <button
              onClick={() => {
                window.location.href = `${url}${authUrl}`;
              }}
              className="login__form_cognito_btn"
            >
              {locale('IDS_LOGIN_AZURE_CAPTION')}
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default Login;
