import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import {
  IClustersInitialStoreState,
  IClustersResponseData,
  IClusterResponseData,
  Pending
} from 'types';

const initialState: IClustersInitialStoreState = {
  clusters: null,
  selectedCluster: null,
  getClustersFetching: false,
  clustersLastUpdate: ''
};

export const clustersSlice = createSlice({
  name: 'clusters',
  initialState,
  reducers: {
    setClusters: (state, action: PayloadAction<IClustersResponseData | null>) => {
      state.clusters = action.payload;
    },
    setSelectedCluster: (state, action: PayloadAction<IClusterResponseData | null>) => {
      state.selectedCluster = action.payload;
    },
    setGetClustersFetching: (state, action: PayloadAction<Pending>) => {
      state.getClustersFetching = action.payload;
    },
    setClustersLastUpdate: (state, action: PayloadAction<string>) => {
      state.clustersLastUpdate = action.payload;
    }
  }
});

// Action creators are generated for each case reducer function
export const { setSelectedCluster, setClusters, setClustersLastUpdate, setGetClustersFetching } =
  clustersSlice.actions;

export default clustersSlice.reducer;
