import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { IAppInitialStoreState, IAppIsLoginType, IAppTokenType, IAppScreenSize } from 'types';

const initialState: IAppInitialStoreState = {
  isLogin: false,
  authorizationFetching: false,
  authorizationError: '',
  token: '',
  screenSize: null,
  getInitDataFetching: false
};

export const appSlice = createSlice({
  name: 'login',
  initialState,
  reducers: {
    setAuthorizationFetching: (state, action) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.authorizationFetching = action.payload;
    },
    setAuthorizationError: (state, action) => {
      state.authorizationError = action.payload;
    },
    setScreenSize: (state, action: PayloadAction<IAppScreenSize>) => {
      state.screenSize = action.payload;
    },
    setToken: (state, action: PayloadAction<IAppTokenType>) => {
      state.token = action.payload;
    },
    changeLoginState: (state, action: PayloadAction<IAppIsLoginType>) => {
      state.isLogin = action.payload;
    },
    setGetInitDataFetching: (state, action) => {
      state.getInitDataFetching = action.payload;
    }
  }
});

// Action creators are generated for each case reducer function
export const {
  changeLoginState,
  setAuthorizationFetching,
  setAuthorizationError,
  setToken,
  setScreenSize,
  setGetInitDataFetching
} = appSlice.actions;

export default appSlice.reducer;
