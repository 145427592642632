import * as api from 'api/api';
import { axiosPrivate } from 'api/axiosPrivate';
import { setBuckets } from 'store/features/buckets/bucketsSlice';
import { AppDispatch, IBucketsGetRequestProps } from 'types';

export const getBuckets =
  ({ clusterId }: IBucketsGetRequestProps) =>
  (dispatch: AppDispatch) => {
    axiosPrivate
      .get(`${api.clusters}/${clusterId}/buckets`)
      .then((response) => {
        dispatch(setBuckets(response?.data || null));
      })
      .catch((error) => {
        console.error('error', error);
      });
  };
