export enum CLUSTER_STATUS {
  ok = 'ok',
  warning = 'warning',
  critical = 'critical'
}

export const CLUSTERS_TABLE_COLUMN_ID = {
  name: 'name',
  companyName: 'companyName',
  bucketsCount: 'bucketsCount',
  nodesCount: 'nodesCount',
  version: 'version',
  lastUpdate: 'getLastUpdate',
  storage: 'getStorage',
  status: 'getStatus',
  alerts: 'getAlerts',
  action: 'getAction'
};

export const CLUSTERS_TABLE_SORT_ID = {
  lastUpdate: 'lastUpdate',
  storage: 'getUsedPercentage',
  status: 'getStatusPriority',
  alerts: 'getAlertsSortColumns'
};

export const CLUSTERS_TABLE_COLUMN_WIDTH = {
  [CLUSTERS_TABLE_COLUMN_ID.name]: '13%',
  [CLUSTERS_TABLE_COLUMN_ID.companyName]: '13%',
  [CLUSTERS_TABLE_COLUMN_ID.bucketsCount]: '5%',
  [CLUSTERS_TABLE_COLUMN_ID.nodesCount]: '5%',
  [CLUSTERS_TABLE_COLUMN_ID.version]: '6%',
  [CLUSTERS_TABLE_COLUMN_ID.lastUpdate]: '13%',
  [CLUSTERS_TABLE_COLUMN_ID.storage]: '15%',
  [CLUSTERS_TABLE_COLUMN_ID.status]: '10%',
  [CLUSTERS_TABLE_COLUMN_ID.alerts]: '10%',
  [CLUSTERS_TABLE_COLUMN_ID.action]: '80px'
};

export const CLUSTERS_TABLE_COLUMN_DISABLED_RESIZE = {
  [CLUSTERS_TABLE_COLUMN_ID.action]: true
};
