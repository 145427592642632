import { createSelector } from '@reduxjs/toolkit';
import { getAlertsCountArr } from 'commonExtensions/common';
import { BUCKETS_DEFAULT_SORT } from 'constants/buckets';
import { convertBytes } from 'commonExtensions/bytesParser';
import { selectAccountSettings } from 'store/selectors/account';
import { TABLE_TYPE } from 'constants/table';
import {
  RootState,
  IAlertsCount,
  IBucketsResponseDataRows,
  IBucketId,
  IBucketName,
  IBucketVersioning,
  IOrganizationsName,
  IBucketLocation,
  IBucketBackup,
  IBucketGeneric,
  IBucketCapacity,
  ITableBody
} from 'types';

class BucketsTable {
  id!: IBucketId;
  name!: IBucketName;
  versioning!: IBucketVersioning;
  owner!: IOrganizationsName;
  location!: IBucketLocation;
  backup!: IBucketBackup;
  generic!: IBucketGeneric;
  capacity!: IBucketCapacity;
  alertsCount!: IAlertsCount;

  constructor(data: IBucketsResponseDataRows) {
    this.id = data.id;
    this.name = data.name;
    this.versioning = data.versioning;
    this.owner = data.owner;
    this.location = data.location;
    this.backup = data.backup;
    this.generic = data.generic;
    this.capacity = data.capacity;
    this.alertsCount = data.alertsCount;
  }

  get getAlerts() {
    return getAlertsCountArr(this.alertsCount);
  }

  get getBackup() {
    return convertBytes(this.capacity);
  }

  get getGeneric() {
    return convertBytes(this.capacity);
  }

  get getTotal() {
    return convertBytes(this.capacity);
  }

  get getCriticalAlerts() {
    return this.alertsCount?.critical || 0;
  }

  get getWarningAlerts() {
    return this.alertsCount?.warning || 0;
  }

  get getAlertsSortColumns() {
    return ['getCriticalAlerts', 'getWarningAlerts'];
  }
}

export const selectBuckets = (state: RootState) => state.buckets.buckets?.rows;

export const selectBucketsTableData = createSelector(
  [selectBuckets, selectAccountSettings],
  (buckets, accountSettings) => {
    return {
      body: buckets?.map((item) => new BucketsTable(item) as ITableBody) || [],
      config: {
        defaultSort: BUCKETS_DEFAULT_SORT
      },
      savedSettings:
        accountSettings?.personalization?.table?.find((item) => item?.id === TABLE_TYPE.BUCKET)
          ?.columns || [],
      type: TABLE_TYPE.BUCKET
    };
  }
);
