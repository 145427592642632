import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { IAccountInitialStoreState, IAccountSettingsResponseData } from 'types';

const initialState: IAccountInitialStoreState = {
  settings: null
};

export const accountSlice = createSlice({
  name: 'account',
  initialState,
  reducers: {
    setAccountSettings: (state, action: PayloadAction<IAccountSettingsResponseData | null>) => {
      state.settings = action.payload;
    }
  }
});

// Action creators are generated for each case reducer function
export const { setAccountSettings } = accountSlice.actions;

export default accountSlice.reducer;
