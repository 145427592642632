import { createSelector } from '@reduxjs/toolkit';
import {
  CLUSTER_STATUS,
  CLUSTERS_TABLE_COLUMN_ID,
  CLUSTERS_TABLE_SORT_ID
} from 'constants/clusters';
import { APP_ROUTE_LOCATION } from 'commonExtensions/constants';
import { ALERT_SEVERITY } from 'constants/alerts';
import { timeParserUtcToDate } from 'commonExtensions/timeParser';
import { clusterStatusLocale } from 'commonExtensions/locales';
import { getAlertsCountArr } from 'commonExtensions/common';
import { convertBytes } from 'commonExtensions/bytesParser';
import { selectAccountSettings } from 'store/selectors/account';
import { TABLE_TYPE, TABLE_SORT_ORDER } from 'constants/table';
import { alertSeverityLocale } from 'commonExtensions/locales';
import {
  IClusterId,
  IClusterName,
  IOrganizationsName,
  IOrganizationsId,
  IClusterBucketsCount,
  IClusterNodesCount,
  IClusterVersion,
  IClusterLastUpdate,
  IClusterCapacity,
  IClusterBackup,
  IClusterGeneric,
  IClusterAllocated,
  IClusterStatus,
  IAlertsCount,
  IClustersResponseDataRows,
  RootState,
  ITableBody
} from 'types';

class ClustersTable {
  id!: IClusterId;
  name!: IClusterName;
  companyName!: IOrganizationsName;
  companyId!: IOrganizationsId;
  bucketsCount!: IClusterBucketsCount;
  nodesCount!: IClusterNodesCount;
  version!: IClusterVersion;
  lastUpdate!: IClusterLastUpdate;
  capacity!: IClusterCapacity;
  backup!: IClusterBackup;
  generic!: IClusterGeneric;
  allocated!: IClusterAllocated;
  status!: IClusterStatus;
  alertsCount!: IAlertsCount;

  constructor(data: IClustersResponseDataRows) {
    this.id = data.id;
    this.name = data.name;
    this.companyName = data.companyName;
    this.companyId = data.companyId;
    this.bucketsCount = data.bucketsCount;
    this.nodesCount = data.nodesCount;
    this.version = data.version;
    this.lastUpdate = data.lastUpdate;
    this.capacity = data.capacity;
    this.backup = data.backup;
    this.generic = data.generic;
    this.allocated = data.allocated;
    this.status = data.status;
    this.alertsCount = data.alertsCount;
  }

  get getLastUpdate() {
    return timeParserUtcToDate({ utcString: this.lastUpdate });
  }

  get getStatus() {
    return clusterStatusLocale[this.status as CLUSTER_STATUS] || '';
  }

  get getStatusPriority() {
    if (this.status === CLUSTER_STATUS.critical) return 0;
    if (this.status === CLUSTER_STATUS.warning) return 1;
    if (this.status === CLUSTER_STATUS.ok) return 2;

    return 0;
  }

  get getCriticalAlerts() {
    return this.alertsCount?.critical || 0;
  }

  get getWarningAlerts() {
    return this.alertsCount?.warning || 0;
  }

  get getAlertsSortColumns() {
    return ['getCriticalAlerts', 'getWarningAlerts'];
  }

  get getAlerts() {
    return getAlertsCountArr(this.alertsCount);
  }

  get getAlertsFilter() {
    return getAlertsCountArr(this.alertsCount)?.map((item) => item?.id);
  }

  get getAlertsFilterLocale() {
    return getAlertsCountArr(this.alertsCount)?.map(
      (item) => alertSeverityLocale?.[item?.id as keyof typeof ALERT_SEVERITY]
    );
  }

  getUsedTotal() {
    return this.backup + this.generic + this.allocated;
  }

  get getUsedPercentage() {
    return +((this.getUsedTotal() * 100) / this.capacity).toFixed(2);
  }

  get getStorage() {
    const usedPrecentage = this.getUsedPercentage;

    return {
      str: `${convertBytes(this.getUsedTotal())}/${convertBytes(this.capacity)}`,
      usedPrecentage
    };
  }

  get getAction() {
    return {
      navigate: `/${APP_ROUTE_LOCATION.dashboard}/${APP_ROUTE_LOCATION.clusters}/${this.id}`
    };
  }
}

export const selectClusters = (state: RootState) => state.clusters.clusters;
export const selectGetClustersFetching = (state: RootState) => state.clusters.getClustersFetching;
export const selectClustersLastUpdate = (state: RootState) => state.clusters.clustersLastUpdate;
export const selectSelectedCluster = (state: RootState) => state.clusters.selectedCluster;

export const selectClustersTableData = createSelector(
  [selectClusters, selectAccountSettings, selectClustersLastUpdate],
  (clusters, accountSettings, lastUpdate) => {
    return {
      body: clusters?.rows?.map((item) => new ClustersTable(item) as ITableBody) || [],
      config: {
        defaultSort: { column: CLUSTERS_TABLE_SORT_ID.alerts, order: TABLE_SORT_ORDER.desc },
        filterOptions: {
          columns: [CLUSTERS_TABLE_COLUMN_ID.status, CLUSTERS_TABLE_COLUMN_ID.alerts]
        }
      },
      savedSettings:
        accountSettings?.personalization?.table?.find((item) => item?.id === TABLE_TYPE.CLUSTER)
          ?.columns || [],
      lastUpdate,
      type: TABLE_TYPE.CLUSTER
    };
  }
);

export const selectSelectedClusterId = createSelector(selectSelectedCluster, (selectedCluster) => {
  return selectedCluster?.id || '';
});
