import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { IBucketsInitialStoreState, IBucketsResponseData } from 'types';

const initialState: IBucketsInitialStoreState = {
  buckets: null
};

export const bucketsSlice = createSlice({
  name: 'buckets',
  initialState,
  reducers: {
    setBuckets: (state, action: PayloadAction<IBucketsResponseData | null>) => {
      state.buckets = action.payload;
    }
  }
});

// Action creators are generated for each case reducer function
export const { setBuckets } = bucketsSlice.actions;

export default bucketsSlice.reducer;
