import { TABLE_SORT_ORDER } from './table';

export const BUCKETS_TABLE_COLUMN_ID = {
  name: 'name',
  versioning: 'versioning',
  owner: 'owner',
  location: 'location',
  backup: 'getBackup',
  generic: 'getGeneric',
  total: 'getTotal',
  alerts: 'getAlerts'
};

export const BUCKETS_TABLE_SORT_ID = {
  name: 'name',
  backup: 'backup',
  generic: 'generic',
  total: 'generic',
  alerts: 'getAlertsSortColumns'
};

export const BUCKETS_TABLE_COLUMN_WIDTH = {
  [BUCKETS_TABLE_COLUMN_ID.name]: '18%',
  [BUCKETS_TABLE_COLUMN_ID.versioning]: '16%',
  [BUCKETS_TABLE_COLUMN_ID.owner]: '16%',
  [BUCKETS_TABLE_COLUMN_ID.location]: '10%',
  [BUCKETS_TABLE_COLUMN_ID.backup]: '10%',
  [BUCKETS_TABLE_COLUMN_ID.generic]: '10%',
  [BUCKETS_TABLE_COLUMN_ID.total]: '10%',
  [BUCKETS_TABLE_COLUMN_ID.alerts]: '10%'
};

export const BUCKETS_DEFAULT_SORT = {
  column: BUCKETS_TABLE_SORT_ID.alerts,
  order: TABLE_SORT_ORDER.desc
};
