import { FC, useEffect } from 'react';
import Loader from 'components/Loader';

import { LOADER_TYPE } from 'commonExtensions/constants';
import { IAppFallbackProps } from 'types';

const Fallback: FC<IAppFallbackProps> = ({ onUnmount }) => {
  useEffect(() => {
    return () => {
      if (onUnmount) {
        onUnmount();
      }
    };
  }, [onUnmount]);

  return <Loader type={LOADER_TYPE.authorization} />;
};

export default Fallback;
