import * as api from 'api/api';
import { axiosPrivate } from 'api/axiosPrivate';
import { getCurrentTime } from 'commonExtensions/timeParser';
import {
  setClusters,
  setSelectedCluster,
  setGetClustersFetching,
  setClustersLastUpdate
} from 'store/features/clusters/clustersSlice';
import { AppDispatch, IClusterGetRequestProps } from 'types';

export const getClusters = () => async (dispatch: AppDispatch) => {
  dispatch(setGetClustersFetching(true));

  await axiosPrivate
    .get(`${api.clusters}`)
    .then((response) => {
      dispatch(setClusters(response?.data || null));
      dispatch(setClustersLastUpdate(getCurrentTime()));
      dispatch(setGetClustersFetching(false));
    })
    .catch((error) => {
      console.error('error', error);
      dispatch(setClustersLastUpdate(getCurrentTime()));
      dispatch(setGetClustersFetching(false));
    });
};

export const getSelectedCluster =
  ({ clusterId }: IClusterGetRequestProps) =>
  (dispatch: AppDispatch) => {
    axiosPrivate
      .get(`${api.clusters}/${clusterId}`)
      .then((response) => {
        dispatch(setSelectedCluster(response?.data || null));
      })
      .catch((error) => {
        console.error('error', error);
      });
  };
