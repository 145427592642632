import { createSelector } from '@reduxjs/toolkit';
import { getAlertsCountArr } from 'commonExtensions/common';
import {
  DISKS_DEFAULT_SORT,
  DISKS_LED_STATUS,
  DISKS_ROLE,
  DISKS_TYPE,
  disksHotSpareLocale,
  disksLedStatusLocale
} from 'constants/disks';
import { convertBytes } from 'commonExtensions/bytesParser';
import { selectAccountSettings } from 'store/selectors/account';
import { TABLE_TYPE } from 'constants/table';
import {
  RootState,
  IAlertsCount,
  IDisksResponseDataRows,
  IDiskId,
  IDiskName,
  IDiskCapacity,
  ITableBody,
  IDisksResponseDataDisks,
  IDiskType,
  IDiskRole,
  IDiskBandwidth,
  IDiskHotSpare,
  IDiskSlot,
  IDiskLedStatus
} from 'types';

class NodeDisksTable {
  id!: IDiskId;
  name!: IDiskName;
  type!: IDiskType;
  role!: IDiskRole;
  bandwidth!: IDiskBandwidth;
  hotSpare!: IDiskHotSpare;
  slot!: IDiskSlot;
  ledStatus!: IDiskLedStatus;
  capacity!: IDiskCapacity;
  alertsCount!: IAlertsCount;

  constructor(data: IDisksResponseDataDisks) {
    this.id = data.id;
    this.name = data.name;
    this.type = data.type;
    this.role = data.role;
    this.bandwidth = data.bandwidth;
    this.hotSpare = data.hotSpare;
    this.slot = data.slot;
    this.ledStatus = data.ledStatus;
    this.capacity = data.capacity;
    this.alertsCount = data.alertsCount;
  }

  get getBandwidth() {
    return `${convertBytes(this.bandwidth)}/s`;
  }

  get getHotSpare() {
    return disksHotSpareLocale[`${this.hotSpare}`];
  }

  get getLedStatus() {
    return disksLedStatusLocale[this.ledStatus];
  }

  get getAlerts() {
    return getAlertsCountArr(this.alertsCount);
  }

  get getSize() {
    return convertBytes(this.capacity);
  }

  get getCriticalAlerts() {
    return this.alertsCount?.critical || 0;
  }

  get getWarningAlerts() {
    return this.alertsCount?.warning || 0;
  }

  get getAlertsSortColumns() {
    return ['getCriticalAlerts', 'getWarningAlerts'];
  }
}

export const selectDisks = (state: RootState) => state.disks.disks?.rows;

export const selectAllDisks = createSelector([selectDisks], (disksItems) => {
  return (disksItems || []).reduce(
    (
      result: IDisksResponseDataDisks[],
      item: IDisksResponseDataRows
    ): IDisksResponseDataDisks[] => {
      const disks = item.disks.map((disk) => ({
        ...disk,
        type: 'SSD' as DISKS_TYPE,
        role: 'OS' as DISKS_ROLE,
        bandwidth: 10000,
        hotSpare: true,
        slot: 8,
        ledStatus: 'inactive' as DISKS_LED_STATUS
      }));
      return [...result, ...disks];
    },
    []
  );
});

export const selectDisksTableData = createSelector(
  [selectAllDisks, selectAccountSettings],
  (disks, accountSettings) => {
    return {
      body: disks?.map((item) => new NodeDisksTable(item) as ITableBody) || [],
      config: {
        defaultSort: DISKS_DEFAULT_SORT
      },
      savedSettings:
        accountSettings?.personalization?.table?.find((item) => item?.id === TABLE_TYPE.DISK)
          ?.columns || [],
      type: TABLE_TYPE.DISK
    };
  }
);
