import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { INodesInitialStoreState, INodeResponseData, INodesResponseData } from 'types';

const initialState: INodesInitialStoreState = {
  nodes: null,
  selectedNode: null
};

export const nodesSlice = createSlice({
  name: 'nodes',
  initialState,
  reducers: {
    setNodes: (state, action: PayloadAction<INodesResponseData | null>) => {
      state.nodes = action.payload;
    },
    setSelectedNode: (state, action: PayloadAction<INodeResponseData | null>) => {
      state.selectedNode = action.payload;
    }
  }
});

// Action creators are generated for each case reducer function
export const { setSelectedNode, setNodes } = nodesSlice.actions;

export default nodesSlice.reducer;
