import * as api from 'api/api';
import { axiosPublic } from 'api/axiosPublic';
import {
  changeLoginState,
  setAuthorizationFetching,
  setToken,
  setAuthorizationError,
  setGetInitDataFetching
} from 'store/features/app/appSlice';

import {
  getOrganizations,
  getClusters,
  getAccountSettings,
  getSelectedOrganization
} from 'store/actions';
import {
  IAppTokenResponseModel,
  IAppLoginRequestData,
  AppDispatch,
  IOrganizationsResponseData,
  IOrganizationsResponseDataRows
} from 'types';
import locale from 'locale';

export const authorization = (data: IAppLoginRequestData) => (dispatch: AppDispatch) => {
  dispatch(setAuthorizationFetching(true));

  axiosPublic
    .post<IAppLoginRequestData, IAppTokenResponseModel>(api.loginCredentials, data)
    .then((response) => {
      // localStorage.setItem('session', JSON.stringify(response?.data));
      // localStorage.setItem('autologin', authData.autologin);
      localStorage.setItem('token', response?.data?.token);
      localStorage.setItem('isLogin', 'true');

      dispatch(setToken(response?.data?.token));
      dispatch(onBrowserRefresh({ isLogin: true }));
      window.location.reload();
    })
    .catch((error) => {
      const getErrorMsg = () => {
        if (error?.response?.status === 401) return locale('IDS_LOGIN_INVALIDCREDS_ERROR_MSG');
        if (error?.response?.status === 500)
          return locale('IDS_LOGIN_INTERNALSERVERERROR_ERROR_MSG');

        return locale('IDS_LOGIN_UNNABLETOCONNECTTOBACKEND_ERROR_MSG');
      };
      dispatch(setAuthorizationError(getErrorMsg()));
      dispatch(setAuthorizationFetching(false));
      // console.log('error', error)
    });
};

export const logoutOut = async (): Promise<void> => {
  try {
    await axiosPublic.get(api.logOut);
    // localStorage.removeItem('lastEventId');
    // localStorage.removeItem('session');
    localStorage.removeItem('token');
    localStorage.removeItem('isLogin');
  } catch (error) {
    console.error('error', error);
  } finally {
    window.location.reload();
  }
};

export const onBrowserRefresh =
  ({ isLogin }: { isLogin?: boolean }) =>
  (dispatch: AppDispatch) => {
    if (!isLogin) {
      dispatch(setGetInitDataFetching(true));
    }

    const finalActions = (error?: boolean) => {
      if (!error) {
        localStorage.setItem('isLogin', 'true');
        dispatch(changeLoginState(true));
      }

      if (isLogin) {
        dispatch(setAuthorizationFetching(false));
      } else {
        dispatch(setGetInitDataFetching(false));
      }
    };

    dispatch(getAccountSettings())
      .then(() => {
        dispatch(getOrganizations()).then(
          (orgResponse: IOrganizationsResponseData | null | undefined) => {
            const checkOrgs = async (orgResponseData: IOrganizationsResponseDataRows[]) => {
              if (orgResponseData.length === 1) {
                return await dispatch(getSelectedOrganization({ orgId: orgResponseData[0]?.id }));
              } else {
                return await dispatch(getClusters());
              }
            };

            checkOrgs(orgResponse?.rows || []).then(() => {
              finalActions();
            });
          }
        );
      })
      .catch((error) => {
        console.error('error', error);
        finalActions(true);
      });
  };
