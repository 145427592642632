import locale from 'locale';
import { STATUS_LABEL_TYPE, ADAPTER_STATUS, ADAPTER_ASSIGNMENT } from 'types';

import { TABLE_SORT_ORDER } from './table';

export const NETWORKS_TABLE_COLUMN_ID = {
  name: 'name',
  status: 'status',
  ipAddress: 'ipAddress',
  bandwidth: 'getBandwidth',
  speed: 'getSpeed',
  mtu: 'getMtu',
  assignment: 'getAssignment',
  alerts: 'getAlerts'
};

export const NETWORKS_TABLE_SORT_ID = {
  name: 'name',
  status: 'status',
  ipAddress: 'ipAddress',
  bandwidth: 'bandwidth',
  speed: 'speed',
  mtu: 'mtu',
  assignment: 'getAssignment',
  alerts: 'getAlertsSortColumns'
};

export const NETWORKS_TABLE_COLUMN_WIDTH = {
  [NETWORKS_TABLE_COLUMN_ID.name]: '25%',
  [NETWORKS_TABLE_COLUMN_ID.status]: '10%',
  [NETWORKS_TABLE_COLUMN_ID.ipAddress]: '10%',
  [NETWORKS_TABLE_COLUMN_ID.bandwidth]: '10%',
  [NETWORKS_TABLE_COLUMN_ID.speed]: '10%',
  [NETWORKS_TABLE_COLUMN_ID.mtu]: '10%',
  [NETWORKS_TABLE_COLUMN_ID.assignment]: '10%',
  [NETWORKS_TABLE_COLUMN_ID.alerts]: '10%'
};

export const NETWORKS_DEFAULT_SORT = {
  column: NETWORKS_TABLE_SORT_ID.name,
  order: TABLE_SORT_ORDER.desc
};

export const networkStatusLocale = {
  [ADAPTER_STATUS.up]: {
    text: locale('IDS_NETWORK_STATUS_UP'),
    statusLabel: STATUS_LABEL_TYPE.ok
  },
  [ADAPTER_STATUS.warning]: {
    text: locale('IDS_NETWORK_STATUS_WARNING'),
    statusLabel: STATUS_LABEL_TYPE.disabled
  },
  [ADAPTER_STATUS.down]: {
    text: locale('IDS_NETWORK_STATUS_DOWN'),
    statusLabel: STATUS_LABEL_TYPE.critical
  }
};

export const networkAssignmentLocale = {
  [ADAPTER_ASSIGNMENT.management]: locale('IDS_NETWORK_ASSIGNMENT_MANAGEMENT'),
  [ADAPTER_ASSIGNMENT.storage]: locale('IDS_NETWORK_ASSIGNMENT_STORAGE'),
  [ADAPTER_ASSIGNMENT.data]: locale('IDS_NETWORK_ASSIGNMENT_DATA')
};

export const nodeNetworksTableColumnName = {
  name: locale('IDS_NETWORKS_TABLE_COLUMN_NAME'),
  status: locale('IDS_NETWORKS_TABLE_COLUMN_STATUS'),
  ipAddress: locale('IDS_NETWORKS_TABLE_COLUMN_IP_ADDRESS'),
  bandwidth: locale('IDS_NETWORKS_TABLE_COLUMN_BANDWIDTH'),
  speed: locale('IDS_NETWORKS_TABLE_COLUMN_LINK_SPEED'),
  mtu: locale('IDS_NETWORKS_TABLE_COLUMN_MTU'),
  assignment: locale('IDS_NETWORKS_TABLE_COLUMN_ASSIGNMENT'),
  alerts: locale('IDS_NETWORKS_TABLE_COLUMN_ALERTS')
};
