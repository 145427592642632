import { TABLE_SORT_ORDER } from './table';
import locale from '../locale';

export enum DISKS_ROLE {
  Data = 'Data',
  OS = 'OS',
  Cache = 'Cache',
  SSD = 'SSD'
}

export enum DISKS_TYPE {
  HDD = 'HDD',
  SSD = 'SSD',
  NVMe = 'NVMe'
}

export enum DISKS_LED_STATUS {
  inactive = 'inactive',
  active = 'active',
  blinking = 'blinking'
}

export const DISKS_TABLE_COLUMN_ID = {
  name: 'name',
  type: 'type',
  role: 'role',
  bandwidth: 'getBandwidth',
  hotSpare: 'getHotSpare',
  slot: 'slot',
  ledStatus: 'getLedStatus',
  size: 'getSize',
  alerts: 'getAlerts'
};

export const DISKS_TABLE_SORT_ID = {
  name: 'name',
  type: 'type',
  role: 'role',
  bandwidth: 'bandwidth',
  hotSpare: 'hotSpare',
  slot: 'slot',
  ledStatus: 'ledStatus',
  size: 'capacity',
  alerts: 'getAlertsSortColumns'
};

export const DISKS_TABLE_COLUMN_WIDTH = {
  [DISKS_TABLE_COLUMN_ID.name]: '25%',
  [DISKS_TABLE_COLUMN_ID.size]: '10%',
  [DISKS_TABLE_COLUMN_ID.type]: '10%',
  [DISKS_TABLE_COLUMN_ID.role]: '10%',
  [DISKS_TABLE_COLUMN_ID.bandwidth]: '10%',
  [DISKS_TABLE_COLUMN_ID.hotSpare]: '10%',
  [DISKS_TABLE_COLUMN_ID.slot]: '10%',
  [DISKS_TABLE_COLUMN_ID.ledStatus]: '10%',
  [DISKS_TABLE_COLUMN_ID.alerts]: '10%'
};

export const DISKS_DEFAULT_SORT = {
  column: DISKS_TABLE_SORT_ID.name,
  order: TABLE_SORT_ORDER.desc
};

export const disksHotSpareLocale = {
  true: locale('IDS_DISKS_HOT_SPARE_TRUE'),
  false: locale('IDS_DISKS_HOT_SPARE_FALSE')
};

export const disksLedStatusLocale = {
  [DISKS_LED_STATUS.active]: locale('IDS_DISKS_LED_STATUS_ACTIVE'),
  [DISKS_LED_STATUS.inactive]: locale('IDS_DISKS_LED_STATUS_INACTIVE'),
  [DISKS_LED_STATUS.blinking]: locale('IDS_DISKS_LED_STATUS_BLINKING')
};

export const disksTableColumnNames = {
  name: locale('IDS_DISKS_TABLE_COLUMN_NAME'),
  size: locale('IDS_DISKS_TABLE_COLUMN_SIZE'),
  type: locale('IDS_DISKS_TABLE_COLUMN_TYPE'),
  role: locale('IDS_DISKS_TABLE_COLUMN_ROLE'),
  bandwidth: locale('IDS_DISKS_TABLE_COLUMN_BANDWIDTH'),
  hotSpare: locale('IDS_DISKS_TABLE_COLUMN_HOT_SPARE'),
  slot: locale('IDS_DISKS_TABLE_COLUMN_SLOT'),
  ledStatus: locale('IDS_DISKS_TABLE_COLUMN_LED_STATUS'),
  alerts: locale('IDS_DISKS_TABLE_COLUMN_ALERTS')
};
