import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { INetworksInitialStoreState, INetworksResponseData } from 'types';

const initialState: INetworksInitialStoreState = {
  networks: null
};

export const networksSlice = createSlice({
  name: 'networks',
  initialState,
  reducers: {
    setNetworks: (state, action: PayloadAction<INetworksResponseData | null>) => {
      state.networks = action.payload;
    }
  }
});

// Action creators are generated for each case reducer function
export const { setNetworks } = networksSlice.actions;

export default networksSlice.reducer;
