export enum ALERT_SEVERITY {
  critical = 'critical',
  warning = 'warning',
  ok = 'ok'
}

export enum ALERTS_STATUS {
  solved = 'solved',
  inProgress = 'in_progress'
}

export enum ALERTS_SOURCE {
  bucket = 'bucket',
  cluster = 'cluster',
  node = 'node',
  disk = 'disk',
  network = 'network'
}

export enum ALERTS_FILTER_ID {
  all = 'all'
}
