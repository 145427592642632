import { APP_ROUTE_LOCATION } from 'commonExtensions/constants';
import { ALERT_SEVERITY, ALERTS_SOURCE, ALERTS_STATUS } from 'constants/alerts';
import { CLUSTER_STATUS } from 'constants/clusters';
import { NODE_STATUS } from 'constants/nodes';
import locale from 'locale';

export const navigationTabLocale = {
  [APP_ROUTE_LOCATION.dashboard]: locale('IDS_NAVIGATION_TAB_DASHBOARD_TITLE'),
  [APP_ROUTE_LOCATION.alerts]: locale('IDS_NAVIGATION_TAB_ALERTS_TITLE'),
  [APP_ROUTE_LOCATION.events]: locale('IDS_NAVIGATION_TAB_EVENTS_TITLE')
};

export const alertSeverityLocale = {
  [ALERT_SEVERITY.critical]: locale('IDS_ALERTS_SEVERITY_CRITICAL'),
  [ALERT_SEVERITY.warning]: locale('IDS_ALERTS_SEVERITY_WARNING'),
  [ALERT_SEVERITY.ok]: locale('IDS_ALERTS_SEVERITY_OK')
};

export const alertSourceLocale = {
  [ALERTS_SOURCE.bucket]: locale('IDS_ALERTS_SOURCE_BUCKET'),
  [ALERTS_SOURCE.cluster]: locale('IDS_ALERTS_SOURCE_CLUSTER'),
  [ALERTS_SOURCE.disk]: locale('IDS_ALERTS_SOURCE_DISK'),
  [ALERTS_SOURCE.network]: locale('IDS_ALERTS_SOURCE_NETWORK'),
  [ALERTS_SOURCE.node]: locale('IDS_ALERTS_SOURCE_NODE')
};

export const alertStatusLocale = {
  [ALERTS_STATUS.solved]: locale('IDS_ALERTS_STATUS_SOLVED'),
  [ALERTS_STATUS.inProgress]: locale('IDS_ALERTS_STATUS_IN_PROGRESS')
};

export const clusterStatusLocale = {
  [CLUSTER_STATUS.ok]: locale('IDS_CLUSTERS_STATUS_OK'),
  [CLUSTER_STATUS.warning]: locale('IDS_CLUSTERS_STATUS_WARNING'),
  [CLUSTER_STATUS.critical]: locale('IDS_CLUSTERS_STATUS_CRITICAL')
};

export const clustersTableColumnName = {
  name: locale('IDS_CLUSTERS_TABLE_COLUMN_NAME'),
  companyName: locale('IDS_CLUSTERS_TABLE_COLUMN_COMPANY'),
  bucketsCount: locale('IDS_CLUSTERS_TABLE_COLUMN_BUCKETS'),
  nodesCount: locale('IDS_CLUSTERS_TABLE_COLUMN_NODES'),
  version: locale('IDS_CLUSTERS_TABLE_COLUMN_VERSION'),
  lastUpdate: locale('IDS_CLUSTERS_TABLE_COLUMN_LASTUPDATE'),
  storage: locale('IDS_CLUSTERS_TABLE_COLUMN_STORAGE'),
  status: locale('IDS_CLUSTERS_TABLE_COLUMN_STATUS'),
  alerts: locale('IDS_CLUSTERS_TABLE_COLUMN_ALERTS')
};

export const bucketsTableColumnName = {
  name: locale('IDS_BUCKETS_TABLE_COLUMN_NAME'),
  versioning: locale('IDS_BUCKETS_TABLE_COLUMN_VERSIONING'),
  owner: locale('IDS_BUCKETS_TABLE_COLUMN_OWNER'),
  location: locale('IDS_BUCKETS_TABLE_COLUMN_LOCATION'),
  backup: locale('IDS_BUCKETS_TABLE_COLUMN_BACKUP'),
  generic: locale('IDS_BUCKETS_TABLE_COLUMN_GENERIC'),
  total: locale('IDS_BUCKETS_TABLE_COLUMN_TOTAL'),
  alerts: locale('IDS_BUCKETS_TABLE_COLUMN_ALERTS')
};

export const nodesTableColumnName = {
  name: locale('IDS_NODES_TABLE_COLUMN_NAME'),
  version: locale('IDS_NODES_TABLE_COLUMN_VERSION'),
  lastUpdate: locale('IDS_NODES_TABLE_COLUMN_LASTUPDATE'),
  storage: locale('IDS_NODES_TABLE_COLUMN_STORAGE'),
  status: locale('IDS_NODES_TABLE_COLUMN_STATUS'),
  alerts: locale('IDS_NODES_TABLE_COLUMN_ALERTS')
};

export const nodeStatusLocale = {
  [NODE_STATUS.ok]: locale('IDS_NODES_STATUS_OK'),
  [NODE_STATUS.warning]: locale('IDS_NODES_STATUS_WARNING'),
  [NODE_STATUS.critical]: locale('IDS_NODES_STATUS_CRITICAL')
};
