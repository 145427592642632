import { TABLE_SORT_ORDER } from './table';

export enum NODE_STATUS {
  ok = 'ok',
  warning = 'warning',
  critical = 'critical'
}

export enum NODE_POWER_SUPPLY {
  ok = 'ok',
  failed = 'failed'
}

export enum NODE_BATTERY_STATUS {
  ok = 'ok',
  failed = 'failed'
}

export enum NODE_FAN_STATUS {
  ok = 'ok',
  unknown = 'unknown'
}

export const NODES_TABLE_COLUMN_ID = {
  name: 'name',
  version: 'version',
  lastUpdate: 'lastUpdate',
  storage: 'getStorage',
  status: 'getStatus',
  alerts: 'getAlerts',
  action: 'getAction'
};

export const NODES_TABLE_SORT_ID = {
  name: 'name',
  version: 'version',
  lastUpdate: 'lastUpdate',
  storage: 'getUsedPercentage',
  status: 'getStatusPriority',
  alerts: 'getAlertsSortColumns'
};

export const NODES_TABLE_COLUMN_WIDTH = {
  [NODES_TABLE_COLUMN_ID.name]: '25%',
  [NODES_TABLE_COLUMN_ID.version]: '15%',
  [NODES_TABLE_COLUMN_ID.lastUpdate]: '15%',
  [NODES_TABLE_COLUMN_ID.storage]: '25%',
  [NODES_TABLE_COLUMN_ID.status]: '10%',
  [NODES_TABLE_COLUMN_ID.alerts]: '10%',
  [NODES_TABLE_COLUMN_ID.action]: '80px'
};

export const NODES_DEFAULT_SORT = {
  column: NODES_TABLE_SORT_ID.alerts,
  order: TABLE_SORT_ORDER.desc
};
