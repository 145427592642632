/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useRef, useEffect } from 'react';
import { Tooltip } from 'react-tooltip';
import { useId } from 'react';
import 'react-tooltip/dist/react-tooltip.css';
import './styles.scss';

interface CustomInputProps {
  disabled?: boolean;
  value: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
  warning?: string;
  error?: string;
  showPercent?: string;
  label?: string;
  customClass?: string;
  maxValue?: number; 
}

const Input: React.FC<CustomInputProps> = ({
  disabled = false,
  value,
  onChange,
  warning = '',
  error = '',
  showPercent = '',
  onKeyDown,
  label,
  onBlur,
  customClass,
  maxValue
}) => {
  const [isFocused, setIsFocused] = useState(false);
  const [width, setWidth] = useState(0);
  const [isOverflowed, setIsOverflowed] = useState(false);
  const id = useId();

  const inputWrapperRef = useRef<HTMLDivElement>(null);
  const labelRef = useRef<HTMLLabelElement>(null);

  const handleFocus = () => setIsFocused(true);
  const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    setIsFocused(false);
    if (onBlur) onBlur(event);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    if (maxValue !== undefined && !isNaN(Number(newValue)) && Number(newValue) > maxValue) {
      return; // Не обновляем значение, если оно больше maxValue
    }
    onChange(event);
  };

  useEffect(() => {
    const updateWidth = () => {
      const timeout = setTimeout(() => {
        if (inputWrapperRef.current) {
          const wrapperWidth = inputWrapperRef.current.offsetWidth;
          setWidth(wrapperWidth - 10);
        }
      }, 0);

      return () => clearTimeout(timeout);
    };

    updateWidth();
    window.addEventListener('resize', updateWidth);

    return () => {
      window.removeEventListener('resize', updateWidth);
    };
  }, []);

  useEffect(() => {
    const checkOverflow = () => {
      if (labelRef.current) {
        setIsOverflowed(labelRef.current.scrollWidth > labelRef.current.clientWidth);

        requestAnimationFrame(() => {
          const currentLabel = labelRef?.current;
          if (currentLabel) {
            setIsOverflowed(currentLabel.scrollWidth > currentLabel.clientWidth);
          }
        });
      }
    };

    setTimeout(() => checkOverflow(), 500);
    window.addEventListener('resize', checkOverflow);

    return () => {
      window.removeEventListener('resize', checkOverflow);
    };
  }, [label]);

  const isEmptyOrNull = (value: unknown) => value === null || value === undefined || value === '';

  const getBorderColor = () => {
    if (error) {
      return '1px solid red';
    }
    return '';
  };

  return (
    <div ref={inputWrapperRef} className="custom-input-wrapper">
      <div className="form-group">
        <input
          onKeyDown={onKeyDown}
          type="text"
          disabled={disabled}
          id={id}
          value={value}
          className={`form-input ${customClass ? customClass : ''}`}
          style={{
            border: getBorderColor()
          }}
          onChange={handleChange}
          onFocus={handleFocus}
          onBlur={handleBlur}
        />
        {label && (
          <label
            ref={labelRef}
            htmlFor={id}
            className={`form-label ${isFocused || !isEmptyOrNull(value) ? 'active' : ''}`}
            style={{
              color: error ? 'red' : '',
              width: `${width - 10}px`
            }}
          >
            <span>{label}</span>
          </label>
        )}
      </div>
      {showPercent && <span>{showPercent}</span>}
      {warning && !error && (
        <>
          <div
            style={{ width: `${width}px` }}
            data-tooltip-id="my-tooltip"
            data-tooltip-content={warning}
            data-tooltip-place="bottom"
            className="warningBlock"
          >
            {warning}
          </div>
          <Tooltip id="my-tooltip" place="bottom" variant="dark" className="custom-tooltip" />
        </>
      )}
      {error && <div className="errorBlock">{error}</div>}
      <div
        data-tooltip-id={isOverflowed ? `${id}-tooltip` : undefined}
        data-tooltip-content={isOverflowed ? label : undefined}
        style={{
          width: isFocused || !isEmptyOrNull(value) ? `${width - 15}px` : '0px'
        }}
        className="hiden-hover"
      ></div>
      {isOverflowed && (
        <Tooltip id={`${id}-tooltip`} place="top" variant="dark" className="custom-tooltip" />
      )}
    </div>
  );
};

export default Input;
