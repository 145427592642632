import { createSelector } from '@reduxjs/toolkit';
import { COMPANY_FILTER_ID } from 'commonExtensions/constants';
import { preparedFilterBtnData } from 'components/CompanyFilter/common';
import { RootState, IOrganizationsResponseDataRows } from 'types';
import { selectAccountSettings } from 'store/selectors/account';
import { getInitials } from 'commonExtensions/common';

import locale from 'locale';

export const selectOrganizations = (state: RootState) => state.organizations.organizations;
export const selectSelectedOrg = (state: RootState) => state.organizations.selectedOrg;
export const selectGetSelectedOrganizationFetching = (state: RootState) =>
  state.organizations.getSelectedOrganizationFetching;

export const selectOrgDetailsVisible = createSelector(
  [selectSelectedOrg, selectOrganizations],
  (selectedOrg, orgs) => {
    if (
      selectedOrg &&
      selectedOrg?.id !== COMPANY_FILTER_ID.all &&
      orgs?.rows &&
      orgs.rows.length > 1
    )
      return true;

    return false;
  }
);

export const selectOrgsFilterData = createSelector(
  [selectOrganizations, selectAccountSettings],
  (orgs, accSettings) => {
    return orgs?.rows && orgs.rows.length > 0
      ? preparedFilterBtnData(
          orgs?.rows?.map((item: IOrganizationsResponseDataRows) => ({
            ...item,
            pinned: accSettings?.personalization?.pinnedOrganizations?.includes(item?.id) || false
          })) || []
        )
      : [];
  }
);

export const selectOrgDetails = createSelector([selectSelectedOrg], (selectedOrg) => {
  const stats = [
    {
      title: locale('IDS_COMPANY_DETAILS_CLUSTERS_TITLE'),
      value: selectedOrg?.clustersCount || 0
    },
    {
      title: locale('IDS_COMPANY_DETAILS_NODES_TITLE'),
      value: selectedOrg?.nodesCount || 0
    },
    {
      title: locale('IDS_COMPANY_DETAILS_BUCKETS_TITLE'),
      value: selectedOrg?.bucketsCount || 0
    },
    {
      title: locale('IDS_COMPANY_DETAILS_NETWORKS_TITLE'),
      value: selectedOrg?.networksCount || 0
    }
  ];

  return {
    id: selectedOrg?.id || '',
    name: selectedOrg?.name || '',
    stats,
    avatars:
      selectedOrg?.users?.map((item) => ({
        id: item?.id || '',
        name: item?.name || '',
        initials: getInitials(item?.name || ''),
        email: item?.email || '',
        avatarColor: item?.avatarColor || ''
      })) || []
  };
});
