import {
  IClusterId,
  IAlertsCount,
  INodeId,
  INodeName,
  IAlertsSeverityArr,
  STATUS_LABEL_TYPE
} from './index';
import { NODE_STATUS } from 'constants/nodes';

export type IAdapterId = string;
export type IAdapterName = string;
export type IAdapterIpAddress = string;
export type IAdapterBandwidth = number;
export type IAdapterSpeed = number;
export type IAdapterMtu = number;

export interface INetworksGetRequestProps {
  clusterId: IClusterId;
}

export interface INetworksResponseDataAdapters {
  id: IAdapterId;
  name: IAdapterName;
  status: ADAPTER_STATUS;
  ipAddress: IAdapterIpAddress;
  bandwidth: IAdapterBandwidth;
  speed: IAdapterSpeed;
  mtu: IAdapterMtu;
  assignment: ADAPTER_ASSIGNMENT;
  alertsCount: IAlertsCount;
}

export interface INetworksResponseDataRows {
  id: INodeId;
  nodeName: INodeName;
  status: NODE_STATUS;
  adapters: INetworksResponseDataAdapters[];
}

export interface INetworksResponseData {
  rows: INetworksResponseDataRows[];
}

export interface INetworksInitialStoreState {
  networks: INetworksResponseData | null;
}

export interface INetworksCountResponseData {
  count: number;
  alertsCount: IAlertsCount;
}

export enum ADAPTER_STATUS {
  up = 'up',
  warning = 'warning',
  down = 'down'
}

export enum ADAPTER_ASSIGNMENT {
  management = 'management',
  data = 'data',
  storage = 'storage'
}

export interface INodeNetworksTableBody {
  id: IAdapterId;
  name: IAdapterName;
  status: ADAPTER_STATUS;
  ipAddress: IAdapterIpAddress;
  bandwidth: IAdapterBandwidth;
  speed: IAdapterSpeed;
  mtu: IAdapterMtu;
  assignment: ADAPTER_ASSIGNMENT;
  alertsCount: IAlertsCount;
  getStatus: {
    text: string;
    statusLabel: (typeof STATUS_LABEL_TYPE)[keyof typeof STATUS_LABEL_TYPE];
  };
  getAlerts: IAlertsSeverityArr[];
  getCriticalAlerts: number;
  getWarningAlerts: number;
  getAlertsSortColumns: string[];
}
