import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'types';
import { getInitials } from 'commonExtensions/common';

export const selectAccountSettings = (state: RootState) => state.account.settings;

export const selectCurrentUserAvatarData = createSelector([selectAccountSettings], (value) => {
  return {
    id: value?.id || '',
    name: value?.name || '',
    initials: getInitials(value?.name || ''),
    email: value?.email || '',
    avatarColor: value?.personalization?.avatarColor || ''
  };
});

export const selectAccountPinnedOrgs = createSelector([selectAccountSettings], (value) => {
  return value?.personalization?.pinnedOrganizations || [];
});

export const selectAccountTableSettings = createSelector(
  [selectAccountSettings],
  (accountSettings) => {
    return accountSettings?.personalization?.table || [];
  }
);
