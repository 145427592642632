import { FC } from 'react';

import { LOADER_TYPE } from 'commonExtensions/constants';

import './styles.scss';

interface Props {
  type: LOADER_TYPE.authorization | LOADER_TYPE.widget;
}

const stylesObj = {
  [LOADER_TYPE.authorization]: 'authorization',
  [LOADER_TYPE.widget]: 'widget'
};

const Loader: FC<Props> = ({ type }) => (
  <div className={`loader__wrapper loader__wrapper--${stylesObj[type] || ''}`}>
    <div className="sk-circle">
      <div className={'sk-circle1 sk-child'}></div>
      <div className={'sk-circle2 sk-child'}></div>
      <div className={'sk-circle3 sk-child'}></div>
      <div className={'sk-circle4 sk-child'}></div>
      <div className={'sk-circle5 sk-child'}></div>
      <div className={'sk-circle6 sk-child'}></div>
      <div className={'sk-circle7 sk-child'}></div>
      <div className={'sk-circle8 sk-child'}></div>
      <div className={'sk-circle9 sk-child'}></div>
      <div className={'sk-circle10 sk-child'}></div>
      <div className={'sk-circle11 sk-child'}></div>
      <div className={'sk-circle12 sk-child'}></div>
    </div>
  </div>
);

export default Loader;
