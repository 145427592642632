import orderBy from 'lodash/orderBy';
import { ICompanyFilterBtnData, IAlertsCount } from 'types';

// Function to determine the priority based on alertsCount
const getPriority = (alertsCount: IAlertsCount) => {
  const critical = alertsCount?.critical || 0;
  const warning = alertsCount?.warning || 0;

  return [critical, warning]; // Return both critical and warning for comparison
};

// Function to prepare and sort the data array
export const preparedFilterBtnData = (dataArr: ICompanyFilterBtnData[]) => {
  if (dataArr?.length > 0) {
    return orderBy(
      dataArr,
      [
        (company: ICompanyFilterBtnData) => !company?.pinned, // Sort by pinned (true comes before false)
        (company: ICompanyFilterBtnData) => getPriority(company.alertsCount)[0], // Sort by critical alerts first
        (company: ICompanyFilterBtnData) => getPriority(company.alertsCount)[1], // If critical alerts are the same, sort by warning alerts
        'name' // Finally, sort alphabetically by company name
      ],
      ['asc', 'desc', 'desc', 'asc'] // Sorting order: pinned (asc), critical (desc), warning (desc), name (asc)
    );
  }

  return [];
};
