import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { IAlertsInitialStoreState, IAlertsResponse, IAlertsWidgetAlertsFilterId } from 'types';

const initialState: IAlertsInitialStoreState = {
  alerts: null,
  widgetSelectedFilter: ''
};

export const accountSlice = createSlice({
  name: 'alerts',
  initialState,
  reducers: {
    setAlerts: (state, action: PayloadAction<IAlertsResponse | null>) => {
      state.alerts = action.payload;
    },
    setAlertsWidgetSelectedFilter: (state, action: PayloadAction<IAlertsWidgetAlertsFilterId>) => {
      state.widgetSelectedFilter = action.payload;
    }
  }
});

// Action creators are generated for each case reducer function
export const { setAlerts, setAlertsWidgetSelectedFilter } = accountSlice.actions;

export default accountSlice.reducer;
