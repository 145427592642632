import { configureStore } from '@reduxjs/toolkit';
import logger from 'redux-logger';
import appReducer from './features/app/appSlice';
import orgReducer from './features/organizations/orgSlice';
import accountReducer from './features/account/accountSlice';
import clustersReducer from './features/clusters/clustersSlice';
import nodesReducer from './features/nodes/nodesSlice';
import bucketReducer from './features/buckets/bucketsSlice';
import networksReducer from './features/networks/networksSlice';
import disksReducer from './features/disks/disksSlice';
import alertsReducer from './features/alerts/alertsSlice';

const store = configureStore({
  reducer: {
    account: accountReducer,
    alerts: alertsReducer,
    app: appReducer,
    buckets: bucketReducer,
    clusters: clustersReducer,
    disks: disksReducer,
    networks: networksReducer,
    nodes: nodesReducer,
    organizations: orgReducer
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(logger)
});

export default store;
