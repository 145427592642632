import { createSelector } from '@reduxjs/toolkit';
import groupBy from 'lodash/groupBy';
import {
  RootState,
  IAlertsResponseData,
  IOrganizationsName,
  IAlertTicket,
  IAlertDescription,
  IAlertObject,
  IAlertTime,
  IAlertId,
  IAlertsCount,
  IAlertsWidgetAlertsFilterId
} from 'types';
import { ALERT_SEVERITY, ALERTS_SOURCE, ALERTS_STATUS } from 'constants/alerts';
import { timeParserUtcToDate } from 'commonExtensions/timeParser';
import {
  alertSeverityLocale,
  alertSourceLocale,
  alertStatusLocale
} from 'commonExtensions/locales';

export const selectAlerts = (state: RootState) => state.alerts.alerts;
export const selectAlertsWidgetSelectedFilter = (state: RootState) =>
  state.alerts.widgetSelectedFilter;

class AlertsTable {
  id!: IAlertId;
  severity!: ALERT_SEVERITY | string;
  time!: IAlertTime;
  source!: IAlertsWidgetAlertsFilterId;
  object!: IAlertObject;
  companyName!: IOrganizationsName;
  description!: IAlertDescription;
  ticket!: IAlertTicket;
  status!: ALERTS_STATUS | string;

  constructor(data: IAlertsResponseData) {
    this.id = data.id;
    this.severity = data.severity;
    this.time = data.time;
    this.source = data.source;
    this.object = data.object;
    this.companyName = data.companyName;
    this.description = data.description;
    this.ticket = data.ticket;
    this.status = data.status;
  }

  get getSeverity() {
    return alertSeverityLocale[this.severity as ALERT_SEVERITY] || '';
  }

  get getTime() {
    return timeParserUtcToDate({ utcString: this.time });
  }

  get getSource() {
    return alertSourceLocale[this.source as ALERTS_SOURCE] || '';
  }

  get getTicket() {
    return `#${this.ticket}`;
  }

  get getStatus() {
    return alertStatusLocale[this.status as ALERTS_STATUS] || '';
  }

  get getStatusPriority() {
    if (this.status === ALERT_SEVERITY.critical) return 0;
    if (this.status === ALERT_SEVERITY.warning) return 1;
    if (this.status === ALERT_SEVERITY.ok) return 2;

    return 0;
  }
}

export const selectAlertsWidgeCounter = createSelector([selectAlerts], (alerts) => {
  const arr = [];
  const alertsCount = alerts?.alertsCount as IAlertsCount;

  if (alertsCount) {
    for (const key in alertsCount) {
      if (alertsCount[key as keyof IAlertsCount]) {
        arr.push({
          id: key,
          value: alertsCount[key as keyof IAlertsCount]
        });
      }
    }
  }

  return arr;
});

export const selectAlertsWidgetFilter = createSelector([selectAlerts], (alerts) => {
  const groupedAlerts = groupBy(alerts?.rows, 'source') || {};

  return (
    Object.keys(groupedAlerts).map((key) => ({
      id: key,
      title: alertSourceLocale[key as ALERTS_SOURCE] || ''
    })) || []
  );
});

export const selectAlertsWidgetList = createSelector([selectAlerts], (alerts) => {
  return alerts?.rows?.map((item) => new AlertsTable(item)) || [];
});
