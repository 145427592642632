import { FC } from 'react';

import { IButtonBigProps } from 'types';

import './styles.scss';

const ButtonBig: FC<IButtonBigProps> = ({ type, caption, disabled, onClick }) => (
  <button
    className={`button_big__wrapper button_big__wrapper--${type}`}
    disabled={disabled}
    onClick={onClick}
  >
    {caption}
  </button>
);

export default ButtonBig;
