import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { IDisksInitialStoreState, IDisksResponseData } from 'types';

const initialState: IDisksInitialStoreState = {
  disks: null
};

export const disksSlice = createSlice({
  name: 'disks',
  initialState,
  reducers: {
    setDisks: (state, action: PayloadAction<IDisksResponseData | null>) => {
      state.disks = action.payload;
    }
  }
});

// Action creators are generated for each case reducer function
export const { setDisks } = disksSlice.actions;

export default disksSlice.reducer;
