import * as api from 'api/api';
import { axiosPrivate } from 'api/axiosPrivate';
import { setNetworks } from 'store/features/networks/networksSlice';
import { AppDispatch, INetworksGetRequestProps } from 'types';

export const getNetworks =
  ({ clusterId }: INetworksGetRequestProps) =>
  (dispatch: AppDispatch) => {
    axiosPrivate
      .get(`${api.clusters}/${clusterId}/networks`)
      .then((response) => {
        dispatch(setNetworks(response?.data || null));
      })
      .catch((error) => {
        console.error('error', error);
      });
  };
