import axios, { InternalAxiosRequestConfig } from 'axios';
import { url } from './api';
// import { memoizedRefreshToken } from './refreshToken';
import { CONTENT_TYPE, CACHE_CONTROL } from './axiosPublic';

const DEFAULT_TIMEOUT = 20 * 1000; // 20 sec

axios.defaults.baseURL = url;
axios.defaults.timeout = DEFAULT_TIMEOUT;
axios.defaults.withCredentials = true;

axios.interceptors.request.use(
  async (config: InternalAxiosRequestConfig) => {
    // const session =
    //   typeof localStorage.getItem('session') === 'string'
    //     ? JSON.parse(localStorage.getItem('session') as string)
    //     : null;
    const basicAuthToken = localStorage.getItem('token') || null;

    // We check if headers exist and update them using AxiosHeaders methods.
    if (config.headers) {
      config.headers.set('authorization', `Basic ${basicAuthToken}`);
      config.headers.set('Content-Type', CONTENT_TYPE);
      config.headers.set('Cache-Control', CACHE_CONTROL);
    }

    // if (session?.token) {
    //   config.headers = {
    //     ...axiosPublicHeader,
    //     ...config.headers,
    //     authorization: session.token
    //   };
    // }

    // if (!config?.forceRequest && localStorage.getItem('stopIntervalRequests')) return;
    return config;
  },
  (error) => Promise.reject(error)
);

axios.interceptors.response.use(
  (response) => response,
  async (error) => {
    const config = error?.config;

    if (error?.response?.status === 401) {
      
      localStorage.removeItem('token');
      localStorage.setItem('isLogin', 'false'); 

      
      window.location.href = '/';
    }

    return Promise.reject(error);
  }
);

// axios.interceptors.response.use(
//   (response) => response,
//   async (error) => {
//     const config = error?.config;

//     if (error?.response?.status === 401 && !config?.sent) {
//       config.sent = true;

//       const result = await memoizedRefreshToken();

//       if (result?.token) {
//         config.headers = {
//           ...axiosPublicHeader,
//           ...config.headers,
//           authorization: result.token
//         };
//       }

//       return axios(config);
//     }
//     return Promise.reject(error);
//   }
// );

export const axiosPrivate = axios;
