import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import App from 'containers/App';
import reportWebVitals from './reportWebVitals';
import store from 'store';
import { Node } from 'types';

import 'themes/colors/index.scss';
import './index.css';

const container = document.getElementById('root') as HTMLElement;
const root = createRoot(container);

const render = (Component: Node) => {
  return root.render(
    <Provider store={store}>
      <BrowserRouter>
        <Component />
      </BrowserRouter>
    </Provider>
  );
};

render(App);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
