import { createSelector } from '@reduxjs/toolkit';
import { getAlertsCountArr } from 'commonExtensions/common';
import { timeParserUtcToDate } from 'commonExtensions/timeParser';
import { convertBytes } from 'commonExtensions/bytesParser';
import { selectAccountSettings } from 'store/selectors/account';
import { NODE_STATUS, NODES_DEFAULT_SORT } from 'constants/nodes';
import { TABLE_TYPE } from 'constants/table';
import { nodeStatusLocale } from 'commonExtensions/locales';
import { APP_ROUTE_LOCATION } from 'commonExtensions/constants';
import {
  RootState,
  IAlertsCount,
  ITableBody,
  INodeId,
  INodeName,
  INodeVersion,
  IClusterName,
  INodeUsed,
  INodeCapacity,
  INodeLastUpdate,
  INodesResponseDataRows,
  IClusterId
} from 'types';

class NodesTable {
  id!: INodeId;
  name!: INodeName;
  lastUpdate!: INodeLastUpdate;
  status!: NODE_STATUS;
  version!: INodeVersion;
  owner!: IClusterName;
  clusterId!: IClusterId;
  used!: INodeUsed;
  capacity!: INodeCapacity;
  alertsCount!: IAlertsCount;

  constructor(data: INodesResponseDataRows) {
    this.id = data.id;
    this.clusterId = data.clusterId;
    this.name = data.name;
    this.lastUpdate = data.lastUpdate;
    this.status = data.status;
    this.version = data.version;
    this.owner = data.owner;
    this.used = data.used;
    this.capacity = data.capacity;
    this.alertsCount = data.alertsCount;
  }

  get getLastUpdate() {
    return timeParserUtcToDate({ utcString: this.lastUpdate });
  }

  get getStatus() {
    return nodeStatusLocale[this.status as NODE_STATUS] || '';
  }

  get getStatusPriority() {
    if (this.status === NODE_STATUS.critical) return 0;
    if (this.status === NODE_STATUS.warning) return 1;
    if (this.status === NODE_STATUS.ok) return 2;

    return 0;
  }

  get getAlerts() {
    return getAlertsCountArr(this.alertsCount);
  }

  get getUsedPercentage() {
    return +((this.used * 100) / this.capacity).toFixed(2);
  }

  get getStorage() {
    const usedPrecentage = this.getUsedPercentage;

    return {
      str: `${convertBytes(this.used)}/${convertBytes(this.capacity)}`,
      usedPrecentage
    };
  }

  getAction = (clusterId: IClusterId) => {
    return {
      navigate: `/${APP_ROUTE_LOCATION.dashboard}/${APP_ROUTE_LOCATION.clusters}/${clusterId}/${APP_ROUTE_LOCATION.nodes}/${this.id}`
    };
  };

  get getCriticalAlerts() {
    return this.alertsCount?.critical || 0;
  }

  get getWarningAlerts() {
    return this.alertsCount?.warning || 0;
  }

  get getAlertsSortColumns() {
    return ['getCriticalAlerts', 'getWarningAlerts'];
  }
}

export const selectNodes = (state: RootState) => state.nodes.nodes?.nodes;
export const selectSelectedNode = (state: RootState) => state.nodes.selectedNode;

export const selectNodesTableData = createSelector(
  [selectNodes, selectAccountSettings],
  (nodes, accountSettings) => {
    return {
      body: nodes?.map((item) => new NodesTable(item) as ITableBody) || [],
      config: {
        defaultSort: NODES_DEFAULT_SORT
      },
      savedSettings:
        accountSettings?.personalization?.table?.find((item) => item?.id === TABLE_TYPE.BUCKET)
          ?.columns || [],
      type: TABLE_TYPE.NODE
    };
  }
);
