export enum TABLE_TYPE {
  CLUSTER = 'cluster',
  BUCKET = 'bucket',
  NODE = 'node',
  DISK = 'disk',
  NETWORK = 'network'
}

export enum TABLE_SORT_ORDER {
  desc = 'desc',
  asc = 'asc'
}

export enum IDS_TABLE_FILTER_FIRST_ACTION {
  filter = 'filter',
  search = 'search'
}

export enum TABLE_STATUS_ICON {
  ok = 'ok',
  warning = 'warning',
  critical = 'critical'
}

export const MIN_TABLE_ROWS_SHOW = 10;
