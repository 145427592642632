let url = '';

if (process.env.NODE_ENV === 'development') {
  url = process.env.REACT_APP_PUBLIC_URL || 'http://localhost:3111';
} else {
  url = '';
}

export { url };
export const config = '/api/v1';
export const account = '/api/v1/account';
export const organizations = '/api/v1/organizations';
export const clusters = '/api/v1/clusters';
export const nodes = '/api/v1/nodes';
export const renewToken = '/api/v1/account/renewToken';
export const loginCredentials = '/api/v1/auth/login/credentials';
export const authorization = '/api/v1/auth/login/oauth';
export const logOut = '/api/v1/auth/logout';
export const alerts = '/api/v1/alerts';
export const user = '/api/v1/auth/user';
