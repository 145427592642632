import axios from 'axios';
import { url } from './api';

export const CONTENT_TYPE = 'application/json';
export const CACHE_CONTROL = 'no-cache, no-store, must-revalidate';

export const axiosPublicHeader = {
  'Content-Type': CONTENT_TYPE,
  'Cache-Control': CACHE_CONTROL
};

export const axiosPublic = axios.create({
  baseURL: url,
  headers: axiosPublicHeader,
  withCredentials: true
});
