import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import {
  IOrganizationsInitialStoreState,
  IOrganizationResponseData,
  IOrganizationsResponseData
} from 'types';

const initialState: IOrganizationsInitialStoreState = {
  organizations: null,
  selectedOrg: null,
  getSelectedOrganizationFetching: false,
  selectedOrgLastUpdate: ''
};

export const orgSlice = createSlice({
  name: 'organizations',
  initialState,
  reducers: {
    setOrganizations: (state, action: PayloadAction<IOrganizationsResponseData | null>) => {
      state.organizations = action.payload;
    },
    setSelectedOrg: (state, action: PayloadAction<IOrganizationResponseData | null>) => {
      state.selectedOrg = action.payload;
    },
    setGetSelectedOrganizationFetching: (state, action: PayloadAction<boolean>) => {
      state.getSelectedOrganizationFetching = action.payload;
    },
    setSelectedOrgLastUpdate: (state, action: PayloadAction<string>) => {
      state.selectedOrgLastUpdate = action.payload;
    }
  }
});

// Action creators are generated for each case reducer function
export const {
  setSelectedOrg,
  setOrganizations,
  setSelectedOrgLastUpdate,
  setGetSelectedOrganizationFetching
} = orgSlice.actions;

export default orgSlice.reducer;
